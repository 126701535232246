
.btn {
	font-size: rem(15);
	height: rem(42);
	padding: rem(9) rem(40);
	font-weight: bold;
	color: $black;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	outline: none;
	box-shadow: none;
	font-size: rem(16);
	font-family: $primary-font;
	text-transform: uppercase;
	border: none;
	box-shadow: rem(0.5) rem(0.5) rem(0.5) rem(0.5) $dark-orange, inset 0 rem(2) rem(1) $shadow-white;

	&__line {
		background-color: $blur-orange;
		transition: all 0.2s ease-in-out;
		&:hover {
			background-color: $black;
			color: $blur-orange;
			box-shadow: rem(0.5) rem(0.5) rem(0.5) rem(0.5) $dark-gray, inset 0 rem(2) rem(1) $shadow-white;
			svg {
				path {
					fill: $blur-orange;
				}
			}
		}
		svg {
			transition: all 0.2s ease-in-out;
		}
	}
	&__outline {
		background-color: $black;
		color: $blur-orange;
		transition: all 0.2s ease-in-out;
		box-shadow: rem(0.5) rem(0.5) rem(0.5) rem(0.5) $dark-gray, inset 0 rem(2) rem(1) $shadow-white;
		&:hover {
			background-color: $blur-orange;
			color: $black;
			box-shadow: rem(0.5) rem(0.5) rem(0.5) rem(0.5) $dark-orange, inset 0 rem(2) rem(1) $shadow-white;
			svg {
				path {
					fill: $black;
				}
			}
		}
		svg {
			transition: all 0.2s ease-in-out;
			path {
				transition: all 0.2s ease-in-out;
				fill: $blur-orange;
			}
		}
	}
	
	@include media(sm) {
        padding: rem(9) rem(33);
    }
	&--sm-p {
		@include media(sm) {
			padding: rem(9) rem(20);
		}
	}
}

button:disabled,
button[disabled]{
	border: rem(1) solid $white;
	color: $dark-gray;
	background-color: $light-gray;
	box-shadow: none;
}
button:disabled:hover,
button[disabled]:hover{
	border: rem(1) solid $white;
	color: $dark-gray;
	background-color: $light-gray;
	box-shadow: none;
}
