.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.align-items-start {
  align-items: start;
}
.align-items-baseline {
  align-items: baseline;
}
.align-items-center {
  align-items: center;
}
.flex-column {
  flex-direction: column;
}
.justify-content-space {
  justify-content: space-between;
}
.mt-2 {
  margin-top: rem(20);
}
.w-100 {
  width: 100% !important;
}
.w-49 {
  width: 49% !important;
}
.text-align {
  text-align: center;
}

.justify-content-end {
  justify-content: end;
}
.flex-direction-row {
  flex-direction: row;
}
.flex-direction-column {
  flex-direction: column;
}
.mt-3 {
  margin-top: rem(30);
}
.mt-6 {
  margin-top: rem(60);
}
.mt-lg-3 {
  @media (min-width: 768px) {
    margin-top: rem(30) !important;
  }
}

.h-100 {
  height: 100%;
}
.pl-1 {
  padding-left: rem(4);
}
.pr-1 {
  padding-right: rem(4);
}
.pr-2 {
  padding-right: rem(8);
}
.ml-1 {
  margin-left: rem(6);
}
