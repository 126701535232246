.wrapper {
  display: flex;
  flex-direction: column;

  @include media(xl) {
    width: 100%;
    margin: rem(30) rem(0);
	}
}
.contact-us {
  &__share {
    display: flex;
    justify-content: flex-end;
    @include media(sm) {
      justify-content: center;
    }
  }
}
.wrapper__block {
  margin: rem(15) rem(30);
  @include media(xl) {
    margin: rem(20) 0;
	}
}


