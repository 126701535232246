.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: $shadow-popup;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100% - 1rem);

  &__inner {
    margin: rem(10);
    width: rem(480);
    margin: auto;
    background: $white;
    overflow: auto;
    border-radius: rem(8);
    padding: rem(15) rem(30) rem(30) rem(30);
    @include media(sm) {
      width: 95%;
    }

    input[type="checkbox"] {
      -webkit-transform: scale(1.2,1.2);
    }

    .popup-group {
      align-items: center;
      justify-content: space-between;
      .wrapp-input {
        width: 75%;
        input {
          @extend .baseField;
          width: 100%;
          margin-top: 0;
          border: 0;
        }

        input:focus {
          border: none;
        }
      }
    }
  }
}

.close {
  cursor:pointer;
}
