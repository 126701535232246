.radio {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: rem(10);
    &__btn + &__btn {
        margin-left: rem(10);
    }
	&__btn {
        margin-top: rem(10);
        padding: rem(14.5) rem(10);
        background-color: $light-gray ;
        font-family: $primary-font;
        color: $dark-gray;
        border: rem(2) solid transparent;
        font-size: rem(16);
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
            background-color: $danger-dark;
            color: $white;
        }
        &--active{
            background-color: $active-danger;
            color: $white;
            &:hover {
                background-color: $active-danger;
            }
        }
        @include media(sm) {
            // width: 100%;
            // margin-left: 0 !important;
        }
	}
}
