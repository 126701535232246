.step {
	min-height: rem(336);
	width: 100%;
    @include media(sm) {
        min-height: rem(296) !important;
    }
	&__question {
		text-align: center;
		font-size: rem(21);
	}
    &__description {
        @include media(sm) {
            font-size: rem(11.2) !important;
            margin: 0 !important;
        }
    }
	&__error {
		color: $danger-dark;
		margin-bottom: 0;
		margin-top: rem(8);
	}
}
.result {
    &__container {
		margin: auto;
        max-width: rem(1200);
		width: 100%;
		min-height: rem(800);
	}
	&__wrapper {
		display: flex;
        justify-content: space-between;
        height: auto;
	}
	&__barChart {
		display: flex;
		flex-direction: column;
		width: 45%;
		margin-bottom: rem(20);
	}
	&__chart {
		height: rem(500);
	}
    &__ROI {
        margin-top: rem(20);
        padding: rem(25);
        border-radius: rem(25);
        background-color: $deep-black;
        box-shadow: rem(5) rem(5) rem(5) $silver;
    }
    &__table {
        background-color: $white;
        color: $black;
        border: rem(2) solid $silver;
        box-shadow: rem(5) rem(5) rem(10) $silver;
        width: 100%;
        margin: 0 auto;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                display: flex;
                justify-content: space-between;
                border-bottom: rem(1) solid $gray2;
                font-size: rem(15);
                // align-items: center;
                width:auto;
                .table__label {
                    display: flex;
                    align-items: center;
                    padding: rem(0) rem(10);
                    background-color: $white;
                }
                .table__value {
                    display: flex;
                    justify-content: flex-end;
                    flex-direction: row;
                    align-items: center;
                    // height: 100%;
                    padding: rem(15) rem(10);
                    background-color: $gray;
                    color: $white;
                    max-width: rem(150);
                    min-width: rem(130);
                }
            }
        }
        @include media(sm) {
            width: 95%;
        }
    }
    &__ROI-title {
        text-align: center;
        font-size: rem(30) !important;
        margin: 0;
    }
    &__ROI-percent {
        color: $white;
        text-align: center;
        font-size: rem(30);
        margin: 0;
    }
    &__ROI-description {
        color: $white;
        text-align: center;
        margin-bottom: 0;
    }

    &__disclaimer {
		border-top: rem(1.5) solid $dark-gray;
		margin: rem(90) 0 rem(25) 0;
	}

	&__disclaimer-text {
		color: $light-gray2;
		font-size: rem(14);
		&--mt-15 {
			margin-top: rem(15);
		}
		&--link {
			color: $danger-light !important;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
    &__table {
        @include media(lg) {
			width: 100%;
		}
    }
    &__contact-us {
        width: 51%;
        @include media(md) {
			width: 100%;
            margin-top: rem(20);
		}
    }
	&__container {
		@include media(md) {
			width: 95%;
		}
	}
    &__wrapper  {
        @include media(md) {
			width: 100%;
            flex-wrap: wrap;
		}
    }
    &__barChart {
        @include media(md) {
			width: 100%;
		}
        tspan {
            @include media(sm) {
                font-size: rem(10);
            }
        }
        text {
            @include media(sm) {
                font-size: rem(12);
            }
        }

	}
}

.roi-popup {
    .result {
        &__disclaimer {
            border-top: rem(1.5) solid $dark-gray;
            margin: rem(106) 0 rem(25) 0 !important;
        }
    }
}

.recharts-legend-wrapper{
    bottom: rem(32) !important;
    left: rem(-20) !important;
    width: rem(370) !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    @include media(sm) {
        width: rem(300) !important;
    }
}

.recharts-surface {
    @include media(md) {
        width: 100%;
        left: 0;
    }
    @include media(sm) {
        width: 100% !important;
        left: 0;
    }
}

.flex-column-reverse  {
    display: flex;
    flex-direction: column-reverse;
}