.layout {
	&__title {
		text-align: center;
		font-size: rem(25);
	}
}

.footer {
	display: flex;
	margin-top: rem(20);
	@include media(sm) {
		margin-top: rem(5) !important;
	}
}

.spaceBetween {
	justify-content: space-between;
}
.spaceCenter {
	justify-content: center;
}
