html {
	font-family: $primary-font;
	width: 100%;
	background-image: url('../../assets/images/ROI\ Calculator\ pdf\ page\ 1\ background.png');
}
* {
	box-sizing: border-box;
}
.container {
	width: rem(600);
	margin: 0 auto;
	@include media(sm) {
		width: 100%;
		padding: rem(0) rem(0);
	}
}
div#root {
    padding-bottom: 30px;
}