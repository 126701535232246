.progressBar {
  &__container {
    height: rem(50);
    width: 100%;
    background-color: $light-gray;
    border-radius: rem(20);
    margin: rem(10);
  }
  &__layout {
    height: 100%;
    background-color: $light-green;
    textAlign: 'right';
    border-radius: rem(20);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__text {
    padding: rem(15);
    color: $white;
    font-weight: bold;
  }
}

.recharts-legend-wrapper {
  display: flex;
  justify-content: center;
  bottom: rem(50);
}

.recharts-default-legend {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  flex-direction: row;
  flex-wrap: wrap;
  width: rem(400);
}

.legend-span {
  height: rem(10);
  width: rem(10);
  display: inline-block;
  vertical-align: middle;
  margin-right: rem(4)
}
.legend-li {
  display:inline-block;
  margin-right: rem(10);
}
.recharts-responsive-container {
  @include media(sm) {
    height: 480px!important;
  }
}
.recharts-wrapper {
  @include media(sm) {
    height: 520px!important;
  }
}
