.baseField {
	margin-top: rem(10) !important;
	padding: rem(12) rem(10);
	background-color: $light-gray !important;
	font-family: $primary-font;
	// color: $dark-gray;
	color: #000;
	border: rem(2) solid transparent !important;
	font-size: rem(16) !important;
	outline-offset: -1px;
	border-radius: 0;
	&::placeholder {
		color: $dark-gray2;
	}
	&[type="number"] {
		-moz-appearance: textfield; /* Firefox */
	}
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		/* display: none; <- Crashes Chrome on hover */
		-webkit-appearance: none;
		margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
	}
}
select {
	background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
		no-repeat 100% 50%;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}
.custom-input {
	display: flex;
	flex-direction: column;
	&__field {
		@extend .baseField;
		&--error {
			box-shadow: inset 0 0 0 rem(1) $danger, inset 0 0 0 rem(1) $white;
			outline: none;
		}
		&--select {
			padding: rem(14) rem(10) rem(14) rem(6);
		}
	}
}
textarea {
	height: rem(80);
	padding: rem(10);
	min-height: rem(50);
	color: $dark-gray;
	background-color: $light-gray;
	width: 100%;
	border: rem(2) solid $white;
	font-size: rem(16);
	font-family: $primary-font;
}
