// styles for overlapping prod website


#dm .dmBody div.u_1958708873 {
 background-color: #fff!important;
}

.customClassName .dmPopupPage .data {
 overflow-y: auto !important
}
// #root .result__disclaimer {
//   margin-top: 100px;
// }
.dmPopupPage .data {
 overflow-y: auto!important;
}
.data {
 overflow-y: auto!important;
}
