$font-family-base: Arial, sans-serif;
// colors
$danger: #FF0A00;
$danger-dark: #b90000;
$active-danger: #CB1A27;
$danger-light: #f3a0a0;
$white: #fff;
$dark-gray: #5f5f5f;
$dark-gray2: #868686;
$black: #201C1D;
$light-green: #82ca9d;
$light-gray: #DCDDDE;
$light-gray2: #c9c9c9;
$blur-orange: #F0B04A;
$dark-orange: #BE8D3F;
$deep-black: #000000;
$gray: #ccc;
$gray2: #a8a8a8;
$primary-font: 'NotoSans', sans-serif;
$secondary-font: Roboto, sans-serif;
$shadow-popup: #00000080;
$shadow-white: #ffffff54;
$gray1: #666;
$silver: #808080

