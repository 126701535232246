.title {
	text-align: center;
	font-size: rem(27);
	@include media(sm) {
		font-size: rem(18.6) !important;
		margin: rem(5);
	}
	&--section {
		font-size: rem(22);
	}
	&--subtitle {
		font-size: rem(19);
	}
	&--mobile-sm {
		@include media(sm) {
			font-size: rem(15) !important;
		}
	}
}

p,
label,
span {
	color: $gray1;
	.link {
		color: $active-danger;
		text-decoration: none;
		&:hover {
			cursor: pointer;
		}
	}
}
.text-left {
	text-align: left !important;
}
.text-center {
	text-align: center !important;
}
.text-right {
	text-align: right !important;
}
#dm div.dm-title h4,
#dm div.dmContent h4,
#dm div.dmFooter h4,
#dm div.p_hfcontainer h4 {
	font-size: rem(19) !important;
	@include media(sm) {
		font-size: rem(14.2) !important;
	}
}
#dm div.dmContent h4 {
	font-size: rem(19) !important;
	@include media(sm) {
		font-size: rem(14.2) !important;
	}
}

#dm div.dm-title h2,
#dm div.dmContent h2,
#dm div.dmFooter h2,
#dm div.p_hfcontainer h2 {
	font-size: rem(27) !important;
	@include media(sm) {
		font-size: rem(20) !important;
	}
}
#dm div.dmContent h2 {
	font-size: rem(27) !important;
	@include media(sm) {
		font-size: rem(20) !important;
	}
}
#dm div.dm-title h3,
#dm div.dmContent h3,
#dm div.dmFooter h3,
#dm div.p_hfcontainer h3 {
	font-size: rem(23) !important;
}
#dm div.dmContent h3 {
	font-size: rem(23) !important;
}
p {
	margin: rem(6) 0 !important;
}

h2 {
	margin: rem(8) 0 !important;
}
h4 {
	margin: rem(6) 0 !important;
}
