@font-face {
	font-family: 'Roboto';
	src: local('Roboto'), url(../../assets/fonts/Roboto-Regular.ttf) format('woff');
}


@font-face {
	font-family: 'NotoSans';
	src: local('NotoSans'), url(../../assets/fonts/NotoSans-Regular.ttf) format('woff');
}
html {
    font-family: 'NotoSans';
}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto' !important;
}

.fw400 { font-weight: 400 !important; }
.fw500 { font-weight: 500 !important; }
.fw600 { font-weight: 600 !important; }