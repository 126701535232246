
// Media queries
@mixin media($breakpoint) {
    @if $breakpoint == "sm" {
      @media screen and (max-width: 576px) {
        @content;
      }
    }

    @else if $breakpoint == "md" {
      @media screen and (max-width: 768px) {
        @content;
      }
    }

    @else if $breakpoint == "lg" {
        @media screen and (max-width: 992px) {
        @content;
      }
    }

    @else if $breakpoint == "xl" {
        @media screen and (max-width: 1200px) {
        @content;
      }
    }
}
